import jsPDF from 'jspdf'
import { LicoriceFont } from './fonts/Licorice'
import { AmaticSCFont } from './fonts/AmaticSC'
import { RalewayLightFont } from './fonts/Raleway-Light'
import { RalewaySemiBoldFont } from './fonts/Raleway-SemiBold'

export enum Font {
  Licorice = 'Licorice',
  RalewayLight = 'RalewayLight',
  RalewaySemibold = 'RalewaySemibold',
  AmaticSC = 'Amatic SC'
}

export enum Color {
  Red = '#B56270',
  Blue = '#6BA4BA',
  Grey60 = '#999999',
  Black = '#333333'
}

export const PX_TO_MM_RATIO = 210.0 / 595.0
const getFontAlignmentFactor = (font: Font) => {
  switch (font) {
    case Font.Licorice:
      return 1.25
    case Font.RalewayLight:
      return 1.166666666666667
    case Font.RalewaySemibold:
      return 1.166666666666667
    case Font.AmaticSC:
      return 1.166666666666667
  }
}

interface TextOptions {
  font?: Font,
  size?: number,
  color?: string,
  relativeTo?: {
    x: number,
    y: number,
    width: number
  },
  link?: string | undefined
}

const setTextOptions = (doc: jsPDF, options: TextOptions) => {
  const font = options.font || Font.RalewayLight
  const fontStyle = font === Font.RalewayLight ? 'light' : font === Font.RalewaySemibold ? 'semibold' : 'normal'
  const size = options.size || 8
  const color = options.color || Color.Black

  // Must be set before using getTextWidth !
  doc.setFont(font, fontStyle)
  doc.setFontSize(size)
  doc.setTextColor(color)

  return { font, fontStyle, size, color }
}

const textWidth = (doc: jsPDF, text: string, options: TextOptions) => {
  setTextOptions(doc, options)
  return doc.getTextWidth(text) / PX_TO_MM_RATIO
}

const caluclatePosition = (xInPx: number | 'center', yInPx: number, elementWidthInMM: number, offsetXInMM: number, offsetYInMM: number, offsetWidthInMM: number, yCorrectionInMM: number) => {
  const x = xInPx === 'center' ? (offsetWidthInMM - elementWidthInMM) / 2 : xInPx * PX_TO_MM_RATIO
  const y = yInPx * PX_TO_MM_RATIO
  return {
    posX: offsetXInMM + x,
    posY: offsetYInMM + y + yCorrectionInMM
  }
}

const calculateYCorrectionInMM = (font: Font, size: number) => {
  return size * PX_TO_MM_RATIO / getFontAlignmentFactor(font)
}

const getRelativeToInMM = (doc: jsPDF, relativeTo?: { x: number, y: number, width: number }) => {
  return relativeTo
    ? {
        x: relativeTo.x * PX_TO_MM_RATIO,
        y: relativeTo.y * PX_TO_MM_RATIO,
        width: relativeTo.width * PX_TO_MM_RATIO
      }
    : {
        x: 0,
        y: 0,
        width: doc.internal.pageSize.width
      }
}

const createText = (doc: jsPDF, text: string, xInPx: number | 'center', yInPx: number, options: TextOptions = {}) => {
  const { font, size } = setTextOptions(doc, options)

  const relativeInMM = getRelativeToInMM(doc, options.relativeTo)
  const yCorrectionInMM = calculateYCorrectionInMM(font, size)
  const { posX, posY } = caluclatePosition(xInPx, yInPx, doc.getTextWidth(text), relativeInMM.x, relativeInMM.y, relativeInMM.width, yCorrectionInMM)

  if (options.link) {
    doc.textWithLink(text, posX, posY, { url: options.link })
  } else {
    doc.text(text, posX, posY)
  }
}

const createLine = (doc: jsPDF, line: Array<{text: string, options?: TextOptions}>, xInPx: number | 'center', yInPx: number, options: TextOptions = {}) => {
  const correctedLine = line.map(({ text, options: lineOptions }) => ({ text, options: { ...options, ...lineOptions } }))
  const textWidths = correctedLine.map(({ text, options: lineOptions }) => textWidth(doc, text, lineOptions))
  const totalWidth = textWidths.reduce((acc, width) => acc + width, 0)
  const relativeInMM = getRelativeToInMM(doc, options.relativeTo)

  let x = xInPx === 'center' ? (relativeInMM.width / PX_TO_MM_RATIO - totalWidth) / 2 : xInPx
  correctedLine.forEach(({ text, options: lineOptions }, index) => {
    createText(doc, text, x, yInPx, lineOptions)
    x += textWidths[index]
  })
}

export interface TextRenderer {
  create: (text: string, x: number | 'center', y: number, options?: TextOptions) => void
  createLine: (line: Array<{text: string, options?: TextOptions}>, x: number | 'center', y: number, options?: TextOptions) => void,
  textWidth: (text: string, options: TextOptions) => number
}

const init = (doc: jsPDF): TextRenderer => {
  doc.addFileToVFS('Licorice-Regular.ttf', LicoriceFont)
  doc.addFont('Licorice-Regular.ttf', Font.Licorice, 'normal')
  doc.addFileToVFS('AmaticSC-Regular.ttf', AmaticSCFont)
  doc.addFont('AmaticSC-Regular.ttf', Font.AmaticSC, 'normal')
  doc.addFileToVFS('Raleway-Light.ttf', RalewayLightFont)
  doc.addFont('Raleway-Light.ttf', Font.RalewayLight, 'light')
  doc.addFileToVFS('Raleway-SemiBold.ttf', RalewaySemiBoldFont)
  doc.addFont('Raleway-SemiBold.ttf', Font.RalewaySemibold, 'semibold')

  return {
    create: (text: string, x: number | 'center', y: number, options: TextOptions = {}) => createText(doc, text, x, y, options),
    createLine: (line: Array<{text: string, options?: TextOptions}>, x: number | 'center', y: number, options?: TextOptions) => createLine(doc, line, x, y, options),
    textWidth: (text: string, options: TextOptions) => textWidth(doc, text, options)
  }
}

export const createTextRenderer = (doc: jsPDF) => init(doc)
