import { ButtonHTMLAttributes, ReactNode } from 'react'
import { useMatch, useNavigate, useResolvedPath } from 'react-router-dom'
import { MenuItem } from '@benjaminpetry/sisterhoodmassagen-design'

interface MenuItemProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  url: string;
  className?: string;
  children: ReactNode;
  onClick?: () => void;
}

export const VoucherMenuItem = ({ url, className, children, onClick, ...rest }: MenuItemProps) => {
  const navigate = useNavigate()
  const resolvedPath = useResolvedPath(url)
  const pathMatch = useMatch({ path: resolvedPath.pathname, end: true })
  const active = pathMatch !== null

  const navigateToUrl = () => {
    navigate(url)
    if (onClick) {
      onClick()
    }
  }

  return (
    <MenuItem isActive={active} onClick={navigateToUrl} className={className} {...rest}>{children}</MenuItem>
  )
}
