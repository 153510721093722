import { Button, Spinner, Textfield } from '@benjaminpetry/sisterhoodmassagen-design'
import { useState } from 'react'
import { AuthLoadingState, useAuth } from '../features/admin/AdminContext'
import './AdminPage.scss'
import { VoucherList } from '../features/admin/views/VoucherList'

export const AdminPage = () => {
  // const navigate = useNavigate()
  // const location = useLocation()
  const [key, setKey] = useState('')
  const [error, setError] = useState('')
  const { isAuthenticated, login, loadingState } = useAuth()

  const onUpdateKey = (key: string) => {
    setKey(key)
    setError('')
  }

  const onSubmit = async () => {
    if (key === '') {
      setError('Bitte gib deinen Admin-Key ein.')
      return
    }

    if (!await login(key)) {
      setError('Du konntest nicht eingeloggt werden. Bitte überprüfe deinen Admin-Key.')
    }
  }

  return (
    <div className="c-admin-page">
        {isAuthenticated && <main className='c-admin-page__container c-admin-page__container--authenticated'>
          {loadingState === AuthLoadingState.LOGOUT && <Spinner></Spinner>}
          {loadingState === AuthLoadingState.READY && <VoucherList></VoucherList>}
        </main>}
      {!isAuthenticated && <main className='c-admin-page__container'>
        <div>Hier kannst du dich in den Admin Bereich einloggen.</div>
        <Textfield className='c-admin-page__adminKey' id='adminKey' label='Your Admin Key' value={key} error={error} onChange={evt => onUpdateKey(evt.target.value)} placeholder="" disabled={loadingState !== AuthLoadingState.READY} onEnter={onSubmit}></Textfield>
        <Button classtype='primary' onClick={onSubmit} disabled={loadingState !== AuthLoadingState.READY}>Einloggen</Button>
        {loadingState === AuthLoadingState.LOGIN && <Spinner></Spinner>}
      </main>}
    </div>
  )
}
