import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Api } from '../api'
import { Voucher } from '../features/vouchers/domain'
import { VoucherDetailView } from '../features/vouchers/views/VoucherDetailView'
import { VoucherLoadingView } from '../features/vouchers/views/VoucherLoadingView'
import './VoucherPage.scss'
import { AppRoutes } from './router'
import { VoucherErrorView } from '../features/vouchers/views/VoucherErrorView'
import { VoucherApplication } from '../features/vouchers/application'
import { useVoucherStore } from '../features/vouchers/VoucherStoreContext'

export const VoucherPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { addVoucher } = useVoucherStore()
  const voucherCodeAnchor = location.hash
  const { voucherIndex } = useParams()
  const [voucherLoading, setVoucherLoading] = useState<boolean>(false)
  const [voucherError, setVoucherError] = useState<boolean>(false)
  const [voucher, setVoucher] = useState<Voucher | undefined>(undefined)

  useEffect(() => {
    const [numberError, voucherYearIndex, voucherYear] = VoucherApplication.extractYearAndIndex(voucherIndex)
    const [codeError, voucherCode] = VoucherApplication.extractCode(voucherCodeAnchor)
    if (!numberError && !codeError) {
      const login = async () => {
        setVoucherLoading(true)
        setVoucherError(false)
        try {
          const newVoucher = await Api.voucher.login(voucherYear, voucherYearIndex, voucherCode)
          setVoucher(newVoucher)
          addVoucher(newVoucher)
        } catch {
          setVoucherError(true)
        } finally {
          setVoucherLoading(false)
        }
      }
      login()
    } else {
      navigate(AppRoutes.Home())
    }
  }
  , [voucherIndex, voucherCodeAnchor, navigate])

  return <div className="voucher-page">
    {voucherLoading && <VoucherLoadingView></VoucherLoadingView>}
    {!voucherLoading && voucherError && <VoucherErrorView num={voucherIndex!} code={voucherCodeAnchor.substring(1)}></VoucherErrorView>}
    {!voucherLoading && voucher && <VoucherDetailView voucher={voucher}></VoucherDetailView>}
  </div>
}
