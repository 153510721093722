import { useAuth } from '../../admin/AdminContext'
import { VoucherApplication } from '../application'
import { Voucher } from '../domain'
import './VoucherInformation.scss'

interface VoucherInformationProps {
  voucher: Voucher
}

export const VoucherInformation = ({ voucher }: VoucherInformationProps) => {
  const { isAuthenticated } = useAuth()
  const shortName = VoucherApplication.getNameShort(voucher)
  return <section className={'voucher-information'}>
    <h2>Weitere Informationen</h2>
    <p className='voucher-information__mini-header'>{shortName}-Nummer</p>
    <p>{VoucherApplication.formatYearAndIndex(voucher.voucherYearIndex, voucher.voucherYear)}</p>
    <p className='voucher-information__mini-header'>{shortName}-Code</p>
    <p>{voucher.voucherCode}</p>
    <p className='voucher-information__mini-header'>Ausstelldatum</p>
    <p>{VoucherApplication.formatUTCDate(voucher.issueDate)}</p>
    {isAuthenticated && <p className='voucher-information__mini-header'>Kommentar</p>}
    {isAuthenticated && <p>{voucher.comment ? voucher.comment : 'Kein Kommentar vorhanden'}</p>}
  </section>
}
