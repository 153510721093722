import { Button, Spinner, Textfield } from '@benjaminpetry/sisterhoodmassagen-design'
import { useState } from 'react'
import { Voucher } from '../domain'
import './VoucherRedeem.scss'
import { VoucherApplication, formatToCurrency, formatToNumber } from '../application'
import { HttpError } from '../../../api'
import { VoucherApi } from '../api'

interface VoucherRedeemProps {
  voucher: Voucher,
  onCancel: () => void
}

interface ServicePackage {
  duration: number,
  price: number
}

interface ServiceDescription {
  name: string,
  packages: Array<ServicePackage>
}

const Service: Array<ServiceDescription> = [{
  name: 'Wellnessmassage',
  packages: [
    { duration: 30, price: 30 },
    { duration: 45, price: 45 },
    { duration: 60, price: 60 },
    { duration: 75, price: 75 },
    { duration: 90, price: 90 }
  ]
}, {
  name: 'Schwangerschaftsmassage',
  packages: [
    { duration: 60, price: 65 },
    { duration: 75, price: 80 },
    { duration: 90, price: 95 }
  ]
}, {
  name: 'Postnatale Massage',
  packages: [
    { duration: 60, price: 65 },
    { duration: 75, price: 80 },
    { duration: 90, price: 95 }
  ]
}, {
  name: 'Hormonelle-Balance-Massage',
  packages: [
    { duration: 45, price: 50 }
  ]
}
]

export const VoucherRedeem = ({ voucher, onCancel }: VoucherRedeemProps) => {
  const [text, setText] = useState('')
  const [value, setValue] = useState(voucher.voucherType === 'discount' ? formatToNumber(voucher.value) : '0')
  const [selectedService, setSelectedService] = useState<ServiceDescription | null | 'Other'>(null)
  const [selectedServicePackage, setSelectedServicePackage] = useState<ServicePackage | null>(null)
  const [newCustomer, setNewCustomer] = useState<boolean | null>(null)
  const [error, setError] = useState<string>('')
  const [busy, setBusy] = useState<boolean>(false)

  const updateDescriptionAndValue = (servicePackage: ServicePackage | null, newCustomer: boolean | null) => {
    if (selectedService !== null && selectedService !== 'Other' && servicePackage !== null && newCustomer !== null) {
      setText(`${selectedService.name} (${servicePackage.duration} min.)${newCustomer ? ' - Neukundinrabatt' : ''}`)
      const servicePrice = servicePackage.price * 100 - (newCustomer ? 1000 : 0)
      setValue(voucher.voucherType === 'discount' ? formatToNumber(voucher.value) : formatToNumber(Math.min(voucher.value, servicePrice)))
    }
  }

  const calculateRestValue = () => {
    if (selectedService !== null && selectedService !== 'Other' && selectedServicePackage !== null && newCustomer !== null) {
      const servicePrice = selectedServicePackage.price * 100 - (newCustomer ? 1000 : 0)
      return Math.max(0, servicePrice - Number.parseFloat(value) * 100)
    }
    return 0
  }

  const onServiceSelected = (service: ServiceDescription | 'Other' | null) => {
    setSelectedService(service)
    if (service !== null && service !== 'Other' && service.packages.length === 1) {
      setSelectedServicePackage(service.packages[0])
    } else {
      setSelectedServicePackage(null)
    }
    if (service === 'Other') {
      setSelectedServicePackage(null)
      setNewCustomer(null)
    }
  }

  const onServicePackageSelected = (servicePackage: ServicePackage | null) => {
    setSelectedServicePackage(servicePackage)
    updateDescriptionAndValue(servicePackage, newCustomer)
  }

  const onNewCustomerSelected = (newCustomer: boolean | null) => {
    setNewCustomer(newCustomer)
    updateDescriptionAndValue(selectedServicePackage, newCustomer)
  }

  const onRedeem = async () => {
    const realValue = Number.parseFloat(value) * 100
    if (realValue > voucher.value) {
      setError('Der angegebene Betrag ist höher als das Guthaben. Betrag wurde angepasst.')
      setValue(formatToNumber(voucher.value))
      return
    }

    setBusy(true)
    try {
      await VoucherApi.createHistory(voucher.id, text, -realValue)
      window.setTimeout(() => {
        document.location.reload()
      }, 200)
    } catch (e) {
      if (e instanceof HttpError) {
        setError(e.message)
      }
      setBusy(false)
    }
  }

  const onUpdateValue = (value: string) => {
    setValue(value)
    setError('')
  }

  const renderService = () => {
    return <div className='voucher-redeem__service-selection'>
            <p className='voucher-redeem__mini-header'>{selectedService === null ? 'Wähle einen Service aus' : 'Ausgewählter Service'}</p>
            {selectedService === null && <div className='voucher-redeem__service-selection-buttons'>
              {Service.map(service => <Button key={service.name} onClick={() => onServiceSelected(service)}>{service.name}</Button>)}
              <Button onClick={() => onServiceSelected('Other')}>Anderer Service</Button>
            </div>}
            {selectedService !== null && <p>{selectedService === 'Other' ? 'Anderer Service' : selectedService.name} (<a onClick={() => onServiceSelected(null)}>Ändern</a>)</p>}
          </div>
  }

  const renderServicePackage = (service: ServiceDescription) => {
    return <div className='voucher-redeem__service-package-selection'>
            <p className='voucher-redeem__mini-header'>{selectedServicePackage === null ? 'Wähle eine Dauer aus' : 'Ausgewählte Dauer'}</p>
            {selectedServicePackage === null && <div className='voucher-redeem__service-package-selection-buttons'>
              {service.packages.map(servicePackage => <Button key={servicePackage.duration} onClick={() => onServicePackageSelected(servicePackage)}>{servicePackage.duration} min.</Button>)}
            </div>}
            {selectedServicePackage !== null && <p>{selectedServicePackage.duration} min. | {selectedServicePackage.price} Euro (<a onClick={() => onServicePackageSelected(null)}>Ändern</a>)</p>}
          </div>
  }

  const renderNewCustomer = () => {
    return <div className='voucher-redeem__new-customer-selection'>
            <p className='voucher-redeem__mini-header'>Hey-Sister Aktion</p>
            {newCustomer === null && <div className='voucher-redeem__new-customer-selection-buttons'>
              <Button key={'yes'} onClick={() => onNewCustomerSelected(true)}>Zutreffend</Button>
              <Button key={'no'} onClick={() => onNewCustomerSelected(false)}>Nicht zutreffend</Button>
            </div>}
            {newCustomer !== null && <p>{newCustomer ? 'Zutreffend' : 'Nicht zutreffend'} (<a onClick={() => onNewCustomerSelected(null)}>Ändern</a>)</p>}
          </div>
  }

  const renderDescriptionAndValue = () => {
    const rest = calculateRestValue()
    return <div className='voucher-redeem__description-and-value'>
    <p className='voucher-redeem__mini-header'>Beschreibung und Betrag</p>
    <div className='voucher-redeem__description-and-value-textfields'>
      <Textfield className='voucher-redeem__description' error={error} value={text} onChange={(evt) => setText(evt.target.value)} placeholder='Beschreibung'></Textfield>
      <Textfield className='voucher-redeem__value' value={value} onChange={(evt) => onUpdateValue(evt.target.value)} type='number' placeholder='Betrag' disabled={voucher.voucherType === 'discount'}></Textfield>
    </div>
    {rest > 0 && <p className='voucher-redeem__rest'>Restbetrag: {formatToCurrency(rest)}</p>}
  </div>
  }

  const shortName = VoucherApplication.getNameShort(voucher)
  return <section className={'voucher-redeem'}>
    {!busy && <div className='voucher-redeem__selection'>
      <h2>{shortName} einlösen</h2>
      <p className='voucher-redeem__mini-header'>{shortName} Guthaben</p>
      <p>{VoucherApplication.getValueForDisplay(voucher)}</p>
      {renderService()}
      {selectedService !== null && selectedService !== 'Other' && renderServicePackage(selectedService)}
      {selectedService !== null && selectedService !== 'Other' && selectedServicePackage !== null && renderNewCustomer()}
      {selectedService !== null && (selectedService === 'Other' || (selectedServicePackage !== null && newCustomer !== null)) && renderDescriptionAndValue()}
    </div>}
    {busy && <div className='voucher-redeem__selection voucher-redeem__selection--loading'><Spinner></Spinner><p>{shortName} wird eingelöst...</p></div>}
    <div className="voucher-redeem__button-bar">
      <Button onClick={onCancel} disabled={busy}>Abbrechen</Button>
      <Button classtype='primary' onClick={onRedeem} disabled={text === '' || Number.parseFloat(value) === 0 || busy}>Einlösen</Button>
    </div>
  </section>
}
