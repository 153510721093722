import { ReactNode, createContext, useContext, useState } from 'react'
import { Voucher } from './domain'

const VOUCHER_STORE = 'VOUCHER_STORE'

interface VoucherStoreState {
  previousVouchers: Array<Voucher>
  addVoucher: (voucher: Voucher) => Array<Voucher>
}

// Create a context with an undefined initial value
const VoucherStoreContext = createContext<VoucherStoreState | undefined>(undefined)

// Define the ChatProvider component
const VoucherStoreProvider = ({ children }: { children: ReactNode }) => {
  const [previousVouchers, setPreviousVouchers] = useState<Array<Voucher>>(JSON.parse(localStorage.getItem(VOUCHER_STORE) ?? '[]') as Array<Voucher>)

  // KEINE EXAMPLE VOUCHERS!

  const addVoucher = (voucher: Voucher) => {
    if (!voucher.isExample) {
      const filteredList = previousVouchers.filter(v => v.id !== voucher.id)
      const newList = [voucher, ...filteredList]
      const finalList = newList.length > 5 ? newList.slice(0, 5) : newList
      localStorage.setItem(VOUCHER_STORE, JSON.stringify(finalList))
      setPreviousVouchers(finalList)
      return finalList
    }
    return previousVouchers
  }
  return <VoucherStoreContext.Provider value={{ previousVouchers, addVoucher }}>{children}</VoucherStoreContext.Provider>
}

// Define a custom hook to use the chat context
const useVoucherStore = () => {
  const context = useContext(VoucherStoreContext)
  if (!context) {
    throw new Error('useVoucherStore must be used within a VoucherStoreProvider')
  }
  return context
}

export { VoucherStoreProvider, useVoucherStore }
