import { useNavigate } from 'react-router-dom'
import { VoucherApplication } from '../application'
import './VoucherErrorView.scss'
import { AppRoutes } from '../../../pages/router'
import { Botch, BotchType, Button } from '@benjaminpetry/sisterhoodmassagen-design'

interface VoucherErrorViewProps {
  num: string,
  code: string
}

export const VoucherErrorView = ({ num, code }: VoucherErrorViewProps) => {
  const navigate = useNavigate()
  const emailBody = `Hallo,\nich kann meinen Gutschein/Bonus nicht aufrufen. Hier sind die Gutschein/Bonus Daten:\nNummer: ${num}\nCode: ${code}\nViele Grüße,\n`
  return <main className="voucher-error-view">
    <Botch botchType={BotchType.BlueOrange} className='voucher-error-view__botch voucher-error-view__botch--top'></Botch>
    <Botch botchType={BotchType.YellowPurple} className='voucher-error-view__botch voucher-error-view__botch--bottom'></Botch>
    <h3>Fehler beim Laden</h3>
    <div>Leider stießen wir auf ein kleines Hindernis beim Laden deines Gutscheins oder Entspannungsbonus. Keine Sorge, dies könnte an einigen einfach zu lösenden Dingen liegen:</div>
    <ul>
      <li>Vielleicht hat sich ein kleiner Tippfehler in der Gutschein- oder Bonusnummer eingeschlichen.</li>
      <li>Oder ein Buchstabe im Code wurde nicht ganz richtig erfasst.</li>
      <li>Es könnte auch sein, dass der Gutschein oder Entspannungsbonus noch nicht für die Verwendung freigeschaltet wurde.</li>
    </ul>
    <Button classtype='primary' onClick={() => navigate(AppRoutes.Home(num))}>Versuche es noch einmal</Button>
    <div className='voucher-error-view__help'>Sollte dieses Hindernis öfter deinen Weg kreuzen, zögere nicht, uns zu schreiben. Eine Nachricht an <a href={VoucherApplication.createEmailLink({ recipient: 'info@sisterhoodmassagen.de', subject: 'Problem mit meinem Gutschein/Bonus', body: emailBody })}>info@sisterhoodmassagen.de</a> mit deiner Gutschein- oder Bonusnummer und dem Code genügt, und wir kümmern uns darum eine schnelle Lösung zu finden.</div>
    </main>
}
