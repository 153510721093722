import QRCode from 'qrcode-svg'
import { Voucher } from '../domain'
import { VoucherApplication } from '../application'
import { SvgImage } from '@benjaminpetry/sisterhoodmassagen-design'

interface VoucherQRCodeProps {
  voucher: Voucher,
  onClick: () => void,
  className?: string,
  size: number
}

export const VoucherQRCode = ({ voucher, onClick, className, size }: VoucherQRCodeProps) => {
  const url = process.env.REACT_APP_FE_URL ?? 'https://voucher.sisterhoodmassagen.de'
  const link = `${url}/${VoucherApplication.formatYearAndIndex(voucher.voucherYearIndex, voucher.voucherYear)}#${voucher.voucherCode}`
  const qrcode = new QRCode({
    content: link,
    padding: 0,
    width: size,
    height: size,
    color: '#333333',
    background: '#ffffff',
    ecl: 'L',
    join: true
  })
  const svgString = qrcode.svg()
  const svgDataURL = `data:image/svg+xml;base64,${window.btoa(svgString)}`

  return (
    <SvgImage onClick={onClick} className={className} src={svgDataURL} alt={`QR Code representing the link ${link}`} />
  )
}
