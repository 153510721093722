import { Separator } from '@benjaminpetry/sisterhoodmassagen-design'
import { useVoucherStore } from '../VoucherStoreContext'
import { VoucherPreviousDisplay } from './VoucherPreviousDisplay'
import './VoucherPreviousList.scss'

export const VoucherPreviousList = () => {
  const { previousVouchers } = useVoucherStore()
  return <div className="voucher-previous-list">
    {previousVouchers.length > 0 && <Separator sizeAppearance='small'></Separator>}
    {previousVouchers.length > 0 && <p>{previousVouchers.length === 1 ? 'Dein zuletzt geladener Gutschein.' : `Deine ${previousVouchers.length} zuletzt geladenen Gutscheine:`}</p>}
    {previousVouchers.length > 0 && <div className='voucher-previous-list__voucher-list'>
      {previousVouchers.map(voucher => {
        return <VoucherPreviousDisplay key={voucher.id} voucher={voucher}></VoucherPreviousDisplay>
      })}
    </div>}
    {previousVouchers.length > 0 && <p className='voucher-previous-list__hint'><strong>Hinweis</strong>: Angezeigt wird nur der zuletzt geladene Betrag. Um den aktuellen Betrag eines Gutscheins zu sehen, klicke auf den Gutschein.</p>}
  </div>
}
