import { HistoryEntry } from '../../history/domain'
import { VoucherApplication, formatToCurrency } from '../application'
import { Voucher } from '../domain'
import './VoucherHistory.scss'

interface VoucherHistoryProps {
  voucher: Voucher
}

export const VoucherHistory = ({ voucher }: VoucherHistoryProps) => {
  const name = VoucherApplication.getName(voucher)

  const renderHistoryEntry = (historyEntry: HistoryEntry) => {
    return <tr key={historyEntry.id} className={`voucher-history__entry--${historyEntry.hidden ? 'hidden' : 'visible'}`}>
      <td className='voucher-history__date'>{VoucherApplication.formatUTCDate(historyEntry.date)}</td>
      <td className='voucher-history__description'>{historyEntry.description}</td>
      <td className={`voucher-history__value voucher-history__value--${historyEntry.value >= 0 ? 'positive' : 'negative'}`}>{historyEntry.value >= 0 ? '+' : ''}{formatToCurrency(historyEntry.value)}</td>
    </tr>
  }

  return <section className={'voucher-history'}>
    <h2>Historie</h2>
    <table>
      <thead>
        <tr>
          <th className='voucher-history__date'>Datum</th>
          <th className='voucher-history__description'>Beschreibung</th>
          <th className='voucher-history__value'>Betrag</th>
        </tr>
      </thead>
      <tbody>
        {voucher.history.length === 0 && <tr><td colSpan={3}>Es wurden noch keine Transaktionen auf diesem {name} durchgeführt.</td></tr>}
        {voucher.history.map(renderHistoryEntry)}
      </tbody>
    </table>
  </section>
}
