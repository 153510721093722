import { createHeaders, http } from '../../api/http'
import { Auth } from '../../api/http/auth'
import { apiUrl } from '../../api/util'
import { Voucher, VoucherType } from './domain'

const serviceUrl = `${apiUrl}/vouchers`

// router.get('/:id', validateParams(VoucherSchema.getParams), Auth.validate(), VoucherMiddleware.validateAccess('id'), VoucherMiddleware.validateVoucherExistence('id', true), VoucherController.get)
// router.delete('/:id', validateParams(VoucherSchema.removeParams), Auth.validate(), VoucherMiddleware.validateFullAccess(), VoucherMiddleware.validateVoucherExistence('id', true), VoucherController.remove)

// router.get('/:voucherId/history', HistoryService.routes.createGetAllHandler())
// router.post('/:voucherId/history', HistoryService.routes.createPostHandler())
// router.put('/:voucherId/history/:entryId', HistoryService.routes.createPutHandler())
// router.delete('/:voucherId/history/:entryId', HistoryService.routes.createDeleteHandler())

const create = async (voucherType: VoucherType): Promise<Voucher> => {
  return http.post(`${serviceUrl}`, await createHeaders(), { voucherType })
}

const createHistory = async (voucherId: number, description: string, value: number): Promise<Voucher> => {
  return http.post(`${serviceUrl}/${voucherId}/history`, await createHeaders(), { description, value })
}

const findAll = async (page: number, query: string): Promise<{count: number, vouchers: Array<Voucher>}> => {
  return http.get(`${serviceUrl}/?page=${page}${query ? `&query=${encodeURIComponent(query)}` : ''}`, await createHeaders())
}

const login = async (voucherYear: number, voucherYearIndex: number, voucherCode: string): Promise<Voucher> => {
  const { voucher, token } = await http.post<{voucher: Voucher, token: string}>(`${serviceUrl}/login`, await createHeaders(), { voucherYear, voucherYearIndex, voucherCode })
  Auth.setAuthtoken(token)
  return voucher
}

const logout = async (): Promise<void> => {
  Auth.setAuthtoken(null)
}

export const VoucherApi = {
  create,
  createHistory,
  findAll,
  login,
  logout
}
