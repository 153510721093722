import { Spinner } from '@benjaminpetry/sisterhoodmassagen-design'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../features/admin/AdminContext'
import { VoucherApi } from '../features/vouchers/api'
import { formatToCurrency } from '../features/vouchers/application'
import { Voucher } from '../features/vouchers/domain'
import './StatsPage.scss'
import { AppRoutes } from './router'

export const StatsPage = () => {
  const navigate = useNavigate()
  const [busy, setBusy] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [maxPages, setMaxPages] = useState(0)
  const [list, setList] = useState<Voucher[]>([])
  const { isAuthenticated } = useAuth()

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(AppRoutes.Admin())
    } else {
      const load = async () => {
        setBusy(true)
        try {
          const { count, vouchers } = await VoucherApi.findAll(0, '')
          const listOfPages = Math.max(1, Math.ceil((count - 1) / 20))
          setMaxPages(listOfPages)
          setList(vouchers)
          for (let i = 1; i <= listOfPages; i++) {
            setCurrentPage(i)
            const { vouchers } = await VoucherApi.findAll(i, '')
            setList(list => list.concat(vouchers))
          }
        } finally {
          setList(list => list.filter(v => !v.isExample))
          setBusy(false)
        }
      }
      load()
    }
  }, [])

  const vouchers = list.filter(v => v.voucherType === 'gift-card')
  const discounts = list.filter(v => v.voucherType === 'discount')

  const sumOriginalValues = (data: Voucher[]) => {
    return data.reduce((a, d) => a + (d.history.length >= 1 ? d.history[0].value : 0), 0)
  }
  const sumValues = (data: Voucher[]) => {
    return data.reduce((a, d) => a + d.value, 0)
  }

  const renderTable = (data: Voucher[]) => {
    const redeemedData = data.filter(d => d.isRedeemed)
    const notRedeemedData = data.filter(d => !d.isRedeemed)
    const notRedeemedInvalid = notRedeemedData.filter(d => !d.isValid)
    const notRedeemedValid = notRedeemedData.filter(d => d.isValid)

    return <table>
      <thead>
        <tr>
          <th className='table__left'>Anzahl</th>
          <th className='table__right'>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr className='table__data_total'>
          <td className='table__left'>Gesamt</td>
          <td className='table__right'>{data.length}</td>
        </tr>
        <tr>
          <td className='table__left'>- davon ungültig</td>
          <td className='table__right'>{notRedeemedInvalid.length}</td>
        </tr>
        <tr>
          <td className='table__left'>- davon eingelöst</td>
          <td className='table__right'>{redeemedData.length}</td>
        </tr>
        <tr>
          <td className='table__left'>- davon offen</td>
          <td className='table__right'>{notRedeemedValid.length}</td>
        </tr>
      </tbody>
    </table>
  }

  const renderValueTable = (data: Voucher[]) => {
    const redeemedData = data.filter(d => d.isRedeemed)
    const notRedeemedData = data.filter(d => !d.isRedeemed)
    const notRedeemedInvalid = notRedeemedData.filter(d => !d.isValid)
    const notRedeemedValid = notRedeemedData.filter(d => d.isValid)
    // const partiallyRedeemedData = notRedeemedData.filter(d => (d.history.length >= 1 ? d.history[0].value : 0) !== d.value)
    return <table>
  <thead>
    <tr>
      <th className='table__left'>Wert</th>
      <th className='table__right'>&nbsp;</th>
    </tr>
  </thead>
  <tbody>
    <tr className='table__data_total'>
      <td className='table__left'>Gesamt</td>
      <td className='table__right'>{formatToCurrency(sumOriginalValues(data))}</td>
    </tr>
    <tr>
      <td className='table__left'>- davon ungültig</td>
      <td className='table__right table__data--redeemed'>{formatToCurrency(sumValues(notRedeemedInvalid))}</td>
    </tr>
    <tr>
      <td className='table__left'>- davon eingelöst</td>
      <td className='table__right table__data--redeemed'>{formatToCurrency(sumOriginalValues(redeemedData) + sumOriginalValues(notRedeemedData) - sumValues(notRedeemedData))}</td>
    </tr>
    <tr className='table__data_open'>
      <td className='table__left'>- davon offen</td>
      <td className='table__right table__data--open'>{formatToCurrency(sumValues(notRedeemedValid))}</td>
    </tr>
  </tbody>
</table>
  }

  return (
    <div className="c-stats-page">
      {busy && <Spinner></Spinner>}
      {busy && <p>Daten werden geladen{maxPages === 0 ? '...' : ` (${currentPage}/${maxPages})`}</p>}
      {<main className='c-stats-page__content'>
        <p>Auf dieser Seite werden Statistiken über die Gutscheine angezeigt.</p>
        <h3>Gutscheine</h3>
        {renderTable(vouchers)}
        {renderValueTable(vouchers)}
        <h3>Entspannungsboni</h3>
        {renderTable(discounts)}
        {renderValueTable(discounts)}
        </main>}
    </div>
  )
}
