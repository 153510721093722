import { VoucherApplication } from '../application'
import { Voucher } from '../domain'
import './VoucherExampleHint.scss'

interface VoucherExampleHintProps {
  voucher: Voucher
}

export const VoucherExampleHint = ({ voucher }: VoucherExampleHintProps) => {
  return <section className={'voucher-example-hint'}>Hinweis: Der hier dargestellte {VoucherApplication.getTitle(voucher)} ist nur ein Beispiel und kann nicht eingelöst werden.</section>
}
