import { ReactNode, createContext, useContext, useEffect, useState } from 'react'
import { HttpError } from '../../api'
import { AdminApi } from './api'
import { Admin } from './domain'
import { Auth } from '../../api/http/auth'

const ADMIN_TOKEN = 'ADMIN_TOKEN'
const ADMIN_INFO = 'ADMIN_INFO'

export enum AuthLoadingState {
  READY,
  INITIALIZATION,
  LOGIN,
  LOGOUT
}

interface AuthContextType {
  isAuthenticated: boolean
  loadingState: AuthLoadingState
  login: (key: string) => Promise<boolean>
  logout: () => Promise<boolean>
  error: string
  info: Admin | null
}

// Create a context with an undefined initial value
const AuthContext = createContext<AuthContextType | undefined>(undefined)

// Define the ChatProvider component
const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [info, setInfo] = useState<Admin | null>(null)
  const [error, setError] = useState<string>('')
  const [loadingState, setLoadingState] = useState<AuthLoadingState>(AuthLoadingState.INITIALIZATION)
  const isAuthenticated = !!info

  useEffect(() => {
    const token = localStorage.getItem(ADMIN_TOKEN) ?? null
    const info = JSON.parse(localStorage.getItem(ADMIN_INFO) ?? 'null') as Admin | null
    Auth.setAuthtoken(token)
    setInfo(info)
    setLoadingState(AuthLoadingState.READY)
  }, [])

  const login = async (key: string) => {
    setLoadingState(AuthLoadingState.LOGIN)
    setError('')
    let success = false
    try {
      const { admin, token } = await AdminApi.login(key)
      Auth.setAuthtoken(token)
      localStorage.setItem(ADMIN_TOKEN, token)
      localStorage.setItem(ADMIN_INFO, JSON.stringify(admin))
      setInfo(admin)
      success = true
    } catch (e) {
      if (e instanceof HttpError) {
        setError(e.message)
      }
    } finally {
      setLoadingState(AuthLoadingState.READY)
    }
    return success
  }

  const logout = async () => {
    setLoadingState(AuthLoadingState.LOGOUT)
    try {
      await AdminApi.logout()
      Auth.setAuthtoken(null)
      localStorage.removeItem(ADMIN_TOKEN)
      localStorage.removeItem(ADMIN_INFO)
      setInfo(null)
    } finally {
      setLoadingState(AuthLoadingState.READY)
    }
    return true
  }

  return <AuthContext.Provider value={{ info, error, loadingState, isAuthenticated, login, logout }}>{children}</AuthContext.Provider>
}

// Define a custom hook to use the chat context
const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}

export { AuthProvider, useAuth }
