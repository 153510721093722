import { Button, CalendlyButton, Separator } from '@benjaminpetry/sisterhoodmassagen-design'
import { useState } from 'react'
import { useAuth } from '../../admin/AdminContext'
import { VoucherApplication } from '../application'
import { VoucherCard } from '../components/VoucherCard'
import { VoucherExampleHint } from '../components/VoucherExampleHint'
import { VoucherHistory } from '../components/VoucherHistory'
import { VoucherInformation } from '../components/VoucherInformation'
import { VoucherNotification } from '../components/VoucherNotification'
import { VoucherRedeem } from '../components/VoucherRedeem'
import { Voucher } from '../domain'
import './VoucherDetailView.scss'

interface VoucherDetailViewProps {
  voucher: Voucher
}

export const VoucherDetailView = ({ voucher }: VoucherDetailViewProps) => {
  const { isAuthenticated } = useAuth()
  const [isRedeem, setIsRedeem] = useState(false)

  return <main className='voucher-detail-view'>
    {VoucherApplication.isExampleVoucher(voucher) && <VoucherExampleHint voucher={voucher}></VoucherExampleHint>}
    <div className='voucher-detail-view__container'>
      <div className='voucher-detail-view__scrollable-container'>
        <VoucherCard voucher={voucher}></VoucherCard>
        <Separator sizeAppearance='small'></Separator>
        <CalendlyButton></CalendlyButton>
        {!voucher.isRedeemed && <Button classtype='secondary' onClick={() => VoucherApplication.createPDF(voucher)}>{VoucherApplication.getName(voucher)} drucken</Button>}
        {!voucher.isRedeemed && isAuthenticated && !voucher.isExample && <Button classtype='secondary' onClick={() => setIsRedeem(true)}>{VoucherApplication.getName(voucher)} einlösen</Button>}
        {isRedeem && <VoucherRedeem voucher={voucher} onCancel={() => setIsRedeem(false)}></VoucherRedeem>}
        <Separator sizeAppearance='small'></Separator>
        <VoucherInformation voucher={voucher}></VoucherInformation>
        <VoucherHistory voucher={voucher}></VoucherHistory>
      </div>
    </div>
    {!isRedeem && <VoucherNotification voucher={voucher}></VoucherNotification>}
    </main>
}
