import { createHeaders, http } from '../../api/http'
import { apiUrl } from '../../api/util'
import { Admin } from './domain'

const serviceUrl = `${apiUrl}/admin`

const login = async (adminKey: string): Promise<{admin: Admin, token: string}> => {
  const { admin, token } = await http.post<{admin: Admin, token: string}>(`${serviceUrl}/login`, await createHeaders(), { adminKey })
  return { admin, token }
}

const logout = async (): Promise<void> => {
}

export const AdminApi = {
  login,
  logout
}
