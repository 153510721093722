import { AdminApi } from '../features/admin/api'
import { VoucherApi } from '../features/vouchers/api'
import { HttpError } from './http'

export { HttpError }

export const Api = {
  voucher: VoucherApi,
  admin: AdminApi
}
