import { useEffect, useState } from 'react'
import { Button, Spinner, Textfield } from '@benjaminpetry/sisterhoodmassagen-design'
import { VoucherApi } from '../../vouchers/api'
import { Voucher } from '../../vouchers/domain'
import './VoucherList.scss'
import { VoucherDisplay } from './VoucherDisplay'

export const VoucherList = () => {
  const [listOfVouchers, setListOfVouchers] = useState<Array<Voucher>>([])
  const [query, setQuery] = useState('')
  const [pages, setPages] = useState(1)
  const [page, setPage] = useState(1)
  const [busy, setBusy] = useState(false)

  const load = async (page: number, query: string) => {
    setBusy(true)
    try {
      const { count, vouchers } = await VoucherApi.findAll(page - 1, query)
      setListOfVouchers(vouchers)
      setPages(Math.max(1, Math.ceil((count - 1) / 20)))
    } finally {
      setBusy(false)
    }
  }

  useEffect(() => {
    load(page, query)
  }, [])

  const onPageChanged = (newPage: number) => {
    if (newPage !== page) {
      setPage(newPage)
      load(newPage, query)
    }
  }

  const onQuerySearch = () => {
    setPage(1)
    load(1, query)
  }

  return <div className='voucher-list'>
    <p>Hier siehst du alle Gutscheine/Boni.</p>
    <div className='voucher-list__search-bar'>
      <div className='voucher-list__searchfield'>
        <Textfield id='searchfield' value={query} onChange={(evt) => setQuery(evt.target.value)} disabled={busy} onEnter={() => onQuerySearch()} placeholder='z.B. 01-24 oder Anna Müller'></Textfield>
        {!!query && <Button onClick={() => { setQuery(''); document.getElementById('searchfield')?.focus() }}>x</Button>}
      </div>
      <Button classtype="primary" onClick={() => onQuerySearch()} disabled={busy}>Suchen</Button>
    </div>
    <div className='voucher-list__results'>
      <div className='voucher-list__results-container'>
      {busy && <Spinner></Spinner>}
      {listOfVouchers.length === 0 && <p className='voucher-list__results-empty'>Es wurden keine Gutscheine/Boni gefunden.</p>}
      {!busy && listOfVouchers.map(voucher => <VoucherDisplay key={voucher.id} voucher={voucher}></VoucherDisplay>)}
      </div>
    </div>
    <div className='voucher-list__pages'>
      <Button onClick={() => onPageChanged(1)} disabled={page <= 1 || busy}>&lt;&lt;</Button>
      <Button onClick={() => onPageChanged(Math.max(1, page - 1))} disabled={page <= 1 || busy}>&lt;</Button>
      <p>{page}</p>
      <Button onClick={() => onPageChanged(Math.min(pages, page + 1))} disabled={page >= pages || busy}>&gt;</Button>
      <Button onClick={() => onPageChanged(pages)} disabled={page >= pages || busy}>&gt;&gt;</Button>
    </div>
    </div>
}
