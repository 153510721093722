import { Botch } from '@benjaminpetry/sisterhoodmassagen-design'
import { VoucherApplication } from '../application'
import { Voucher } from '../domain'
import './VoucherPreviousDisplay.scss'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '../../../pages/router'

interface VoucherPreviousDisplayProps {
  voucher: Voucher
}

export const VoucherPreviousDisplay = ({ voucher }: VoucherPreviousDisplayProps) => {
  const navigate = useNavigate()
  const shortName = VoucherApplication.getNameShort(voucher)
  const openVoucher = () => {
    navigate(AppRoutes.Voucher(VoucherApplication.formatYearAndIndex(voucher.voucherYearIndex, voucher.voucherYear), voucher.voucherCode))
  }

  const renderFooter = () => {
    if (voucher.isRedeemed) {
      return <div className='voucher-previous-display__redeemed-text'>Der {shortName} wurde bereits eingelöst.</div>
    } else if (!voucher.isValid) {
      return <div className='voucher-previous-display__invalid-text'>Der {shortName} ist seit dem {VoucherApplication.formatUTCDate(voucher.validUntil)} nicht mehr gültig.</div>
    }
  }

  return <div className={`voucher-previous-display voucher-previous-display--${voucher.isRedeemed ? 'redeemed' : 'redeemable'} voucher-previous-display--${voucher.isValid ? 'valid' : 'invalid'}`} onClick={openVoucher}>
    <Botch botchType={VoucherApplication.getLeftBotchType(voucher)} className='voucher-header__value__botch-left'></Botch>
    <div className='voucher-previous-display__row'>
      <h3 className='voucher-previous-display__name'>{shortName}</h3>
      <div className='voucher-previous-display__value'>{VoucherApplication.getValueForDisplay(voucher)}</div>
    </div>
    <div className='voucher-previous-display__row'>
      <div className={'voucher-previous-display__number'}><span>Nummer</span><br />{VoucherApplication.formatYearAndIndex(voucher.voucherYearIndex, voucher.voucherYear)}</div>
      <div className='voucher-previous-display__date'><span>Ausgestellt am</span><br />{VoucherApplication.formatUTCDate(voucher.issueDate)}</div>
      <div className='voucher-previous-display__date'><span>Gültig bis</span><br />{VoucherApplication.formatUTCDate(voucher.validUntil)}</div>
    </div>
    {renderFooter()}
  </div>
}
