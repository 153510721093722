import { RouteObject } from 'react-router-dom'
import { LoginPage } from './LoginPage'
import { VoucherPage } from './VoucherPage'
import { AdminPage } from './AdminPage'
import { StatsPage } from './StatsPage'

export const AppRoutes = {
  Home: (num?: string) => `/${num ? `?number=${num}` : ''}`,
  Voucher: (voucherNumber: string, voucherCode: string) => `/${voucherNumber}#${voucherCode}`,
  Admin: () => '/admin',
  Stats: () => '/stats'
}

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <LoginPage />
  },
  {
    path: '/admin',
    element: <AdminPage />
  },
  {
    path: '/stats',
    element: <StatsPage />
  },
  {
    path: '/:voucherIndex',
    element: <VoucherPage />
  }
]
