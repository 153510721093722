import { Botch } from '@benjaminpetry/sisterhoodmassagen-design'
import { VoucherApplication } from '../../vouchers/application'
import { Voucher } from '../../vouchers/domain'
import './VoucherDisplay.scss'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '../../../pages/router'

interface VoucherDisplayProps {
  voucher: Voucher
}

export const VoucherDisplay = ({ voucher }: VoucherDisplayProps) => {
  const navigate = useNavigate()
  const openVoucher = () => {
    navigate(AppRoutes.Voucher(VoucherApplication.formatYearAndIndex(voucher.voucherYearIndex, voucher.voucherYear), voucher.voucherCode))
  }

  const shortName = VoucherApplication.getNameShort(voucher)
  return <div className={`voucher-display voucher-display--${voucher.isRedeemed ? 'redeemed' : 'redeemable'}`} onClick={openVoucher}>
    <Botch botchType={VoucherApplication.getLeftBotchType(voucher)} className='voucher-header__value__botch-left'></Botch>
    <div className='voucher-display__row'>
      <h3 className='voucher-display__name'>{shortName}</h3>
      <div className='voucher-display__value'>{VoucherApplication.getValueForDisplay(voucher)}</div>
    </div>
    <div className='voucher-display__row'>
      <div className={`voucher-display__comment voucher-display__comment--${voucher.comment ? 'exists' : 'empty'}`}>{voucher.comment ? voucher.comment : 'Kein Kommentar'}</div>
      <div className='voucher-display__date'>{VoucherApplication.formatYearAndIndex(voucher.voucherYearIndex, voucher.voucherYear)} | {VoucherApplication.formatUTCDate(voucher.issueDate)}</div>
    </div>
    {!voucher.isValid && <div className='voucher-display__invalid-text'>{shortName} ist seit {VoucherApplication.formatUTCDate(voucher.validUntil)} nicht mehr gültig.</div>}
  </div>
}
