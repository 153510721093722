import { Botch } from '@benjaminpetry/sisterhoodmassagen-design'
import { VoucherApplication } from '../application'
import { Voucher } from '../domain'
import './VoucherHeader.scss'

interface VoucherHeaderProps {
  voucher: Voucher,
  onClick: () => void,
  className: string
}

export const VoucherHeader = ({ voucher, onClick, className }: VoucherHeaderProps) => {
  return <section onClick={onClick} className={`voucher-header voucher-header--${voucher.isRedeemed ? 'redeemed' : 'redeemable'} voucher-header--${voucher.isValid ? 'valid' : 'invalid'} voucher-header--${voucher.isDeactivated ? 'deactivated' : 'activated'} voucher--${voucher.voucherType} ${className ?? ''}`}>
        <div className='voucher-header__title'>{VoucherApplication.getName(voucher)}</div>
        <div className='voucher-header__p'>{VoucherApplication.getTitleOpening(voucher)}</div>
        <div className='voucher-header__value'>
          <Botch botchType={VoucherApplication.getLeftBotchType(voucher)} className='voucher-header__value__botch-left'></Botch>
          <Botch botchType={VoucherApplication.getRightBotchType(voucher)} className='voucher-header__value__botch-right'></Botch>
          <div>{VoucherApplication.getValueForDisplay(voucher)}</div>
        </div>
        <div className='voucher-header__p'>{VoucherApplication.getTitleClosure(voucher)}</div>
        </section>
}
