import { Auth } from './auth'

/* eslint-disable no-undef */
const defaultHeaders = {
  'Content-Type': 'application/json'
}

export class HttpError extends Error {
  public statusCode: number
  constructor (message: string, status: number) {
    super(message)
    this.statusCode = status
  }
}

export const createHeaders = (): HeadersInit => {
  const token = Auth.getAuthToken()
  return token === null ? {} : { Authorization: `Bearer ${token}` }
}

enum HttpMethod {
    Get = 'GET',
    Post = 'POST',
    Put = 'PUT',
    Patch = 'PATCH',
    Delete = 'DELETE'
  }

async function request<T> (method: HttpMethod, url: RequestInfo, additionalHeaders?: HeadersInit, body?: object): Promise<T> {
  const headers = { ...defaultHeaders, ...createHeaders(), ...additionalHeaders }
  const init = body ? { method, headers, body: JSON.stringify(body) } : { method, headers }
  const response: Response = await fetch(url, init)

  try {
    const json = await response.json()
    if (response.ok && json.status >= 200 && json.status <= 209) {
      return json.data
    }
    throw new HttpError(json.message, json.status)
  } catch (e) {
    if (e instanceof HttpError) {
      throw e
    }
    throw await response.text()
  }
}

export const http = {
  get<T> (url: RequestInfo, additionalHeaders?: HeadersInit): Promise<T> {
    return request(HttpMethod.Get, url, additionalHeaders)
  },
  post<T> (url: RequestInfo, additionalHeaders?: HeadersInit, body?: object): Promise<T> {
    return request(HttpMethod.Post, url, additionalHeaders, body)
  },
  put<T> (url: RequestInfo, additionalHeaders?: HeadersInit, body?: object): Promise<T> {
    return request(HttpMethod.Put, url, additionalHeaders, body)
  },
  patch<T> (url: RequestInfo, additionalHeaders?: HeadersInit, body?: object): Promise<T> {
    return request(HttpMethod.Patch, url, additionalHeaders, body)
  },
  delete<T> (url: RequestInfo, additionalHeaders?: HeadersInit, body?: object): Promise<T> {
    return request(HttpMethod.Delete, url, additionalHeaders, body)
  }
}
