import { useState } from 'react'
import { VoucherHeader } from '../components/VoucherHeader'
import { VoucherQRCode } from '../components/VoucherQRCode'
import { Voucher } from '../domain'
import './VoucherCard.scss'

interface VoucherCardProps {
  voucher: Voucher
}

export const VoucherCard = ({ voucher }: VoucherCardProps) => {
  const [qrCodeVisible, setQrCodeVisibility] = useState(false)

  return <div className={`card card--show-${qrCodeVisible ? 'back' : 'front'} card--${voucher.voucherType}`}>
        <VoucherHeader className='card__side card__side--front' voucher={voucher} onClick={() => setQrCodeVisibility(true)}></VoucherHeader>
        <div className='card__side card__side--back'><VoucherQRCode size={voucher.voucherType === 'gift-card' ? 300 : 250} voucher={voucher} onClick={() => setQrCodeVisibility(false)}></VoucherQRCode></div>
  </div>
}
