import { Menu, MenuItem } from '@benjaminpetry/sisterhoodmassagen-design'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '../../pages/router'
import { VoucherMenuItem } from './VoucherMenuItem'
import { useAuth } from '../../features/admin/AdminContext'

export const VoucherMenu = () => {
  const navigate = useNavigate()
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false)
  const { isAuthenticated, logout } = useAuth()

  const hideMobileMenu = () => {
    setMobileMenuVisible(false)
  }

  return (
    <Menu isOpenOnMobile={mobileMenuVisible} setIsOpenOnMobile={setMobileMenuVisible} onHomeClick={() => navigate(AppRoutes.Home())}>
      <VoucherMenuItem url={AppRoutes.Home()} onClick={hideMobileMenu}>Gutscheine</VoucherMenuItem>
      {isAuthenticated && <VoucherMenuItem url={AppRoutes.Admin()} onClick={hideMobileMenu}>Admin Übersicht</VoucherMenuItem>}
      {isAuthenticated && <VoucherMenuItem url={AppRoutes.Stats()} onClick={hideMobileMenu}>Statistiken</VoucherMenuItem>}
      <MenuItem isActive={false} onClick={() => window.open('https://www.sisterhoodmassagen.de', '_blank')}>Webseite</MenuItem>
      {isAuthenticated && <MenuItem isActive={false} onClick={() => logout()}>Ausloggen</MenuItem>}
    </Menu>
  )
}
