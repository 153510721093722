import { Voucher } from './domain'
import { PdfRender } from '../pdf/pdfRenderer'
import { BotchType } from '@benjaminpetry/sisterhoodmassagen-design'

const REGEX_VOUCHER_NUMBER = /^0*([1-9][0-9]*)-(2[4-9]|[3-9][0-9])$/
const REGEX_VOUCHER_CODE = /^#?([0-9a-z]{4})-?([0-9a-z]{4})-?([0-9a-z]{4})$/

const extractYearAndIndex = (voucherNumber: string | undefined): [boolean, number, number] => { // first boolean represents if there is an error or not
  const numberMatch = voucherNumber ? voucherNumber.match(REGEX_VOUCHER_NUMBER) : null
  if (numberMatch) {
    const voucherYearIndex = Number.parseInt(numberMatch[1])
    const voucherYear = 2000 + Number.parseInt(numberMatch[2])
    if (voucherYearIndex > 0) {
      return [false, voucherYearIndex, voucherYear]
    }
  }
  return [true, 0, 0]
}

const formatYearAndIndex = (voucherYearIndex: number, voucherYear: number): string => {
  return `${(voucherYearIndex + '').padStart(2, '0')}-${(voucherYear + '').substring(2)}`
}

const extractCode = (voucherCodeAnchor: string | undefined): [boolean, string] => { // first boolean represents if there is an error or not
  const codeMatch = voucherCodeAnchor ? voucherCodeAnchor.match(REGEX_VOUCHER_CODE) : null
  if (codeMatch) {
    const voucherCode = `${codeMatch[1]}-${codeMatch[2]}-${codeMatch[3]}`
    return [false, voucherCode]
  }
  return [true, '']
}

function formatNumber (num: number, currency = ''): string {
  return Intl.NumberFormat(undefined, currency ? { style: 'currency', currency } : { minimumFractionDigits: 2, useGrouping: false }).format(num / 100)
}

export const formatToNumber = (value: number): string => {
  const valueString = formatNumber(value)
  return `${valueString.endsWith('00') ? valueString.substring(0, valueString.length - 3) : valueString}`
}

export const formatToCurrency = (value: number): string => {
  return `${formatToNumber(value)} Euro`
}

const getName = (voucher: Voucher): string => {
  switch (voucher.voucherType) {
    case 'gift-card':
      return 'Gutschein'
    case 'discount':
      return 'Entspannungsbonus'
  }
}

const getNameShort = (voucher: Voucher): string => {
  switch (voucher.voucherType) {
    case 'gift-card':
      return 'Gutschein'
    case 'discount':
      return 'Bonus'
  }
}

const getTitle = (voucher: Voucher): string => {
  return voucher.voucherType === 'gift-card' ? voucher.title : ''
}

const getSubtitle = (voucher: Voucher): string => {
  return voucher.voucherType === 'gift-card' ? voucher.subtitle : ''
}

const getValueForDisplay = (voucher: Voucher): string => {
  return formatToCurrency(voucher.value)
}

const getLeftBotchType = (voucher: Voucher): BotchType => {
  switch (voucher.voucherType) {
    case 'gift-card':
      return BotchType.OrangePink
    case 'discount':
      return BotchType.BlueYellow
  }
}

const getRightBotchType = (voucher: Voucher): BotchType => {
  switch (voucher.voucherType) {
    case 'gift-card':
      return BotchType.BluePurple2
    case 'discount':
      return BotchType.YellowPurple
  }
}

const getTitleOpening = (voucher: Voucher): string => {
  switch (voucher.voucherType) {
    case 'gift-card':
      return 'über'
    case 'discount':
      return 'im Wert von'
  }
}

const getTitleClosure = (voucher: Voucher): string => {
  switch (voucher.voucherType) {
    case 'gift-card':
      return 'Guthaben'
    case 'discount':
      return voucher.value === 0 ? 'wurde eingelöst.' : ''
  }
}

const isExampleVoucher = (voucher: Voucher): boolean => {
  return voucher.isExample
}

const formatUTCDate = (utcDateInMs: number): string => {
  const date = new Date(utcDateInMs)
  return date.toLocaleDateString('de-DE', { timeZone: 'Europe/Berlin', year: 'numeric', month: '2-digit', day: '2-digit' })
}

const createEmailLink = ({ recipient, subject, body }: {recipient: string, subject: string, body: string}) => {
  const subjectEncoded = encodeURIComponent(subject)
  const bodyEncoded = encodeURIComponent(body)
  return `mailto:${recipient}?subject=${subjectEncoded}&body=${bodyEncoded}`
}

const createPDF = (voucher: Voucher) => {
  return PdfRender.create(voucher)
}

export const VoucherApplication = {
  createPDF,
  getName,
  getNameShort,
  getTitle,
  getSubtitle,
  getValueForDisplay,
  getTitleOpening,
  getTitleClosure,
  getLeftBotchType,
  getRightBotchType,
  isExampleVoucher,
  formatUTCDate,
  createEmailLink,
  extractYearAndIndex,
  extractCode,
  formatYearAndIndex
}
