import { Button, Textfield } from '@benjaminpetry/sisterhoodmassagen-design'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { VoucherApplication } from '../features/vouchers/application'
import './LoginPage.scss'
import { AppRoutes } from './router'
import { VoucherPreviousList } from '../features/vouchers/views/VoucherPreviousList'
import { useAuth } from '../features/admin/AdminContext'

export const LoginPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const prefilledNumber = searchParams.get('number') ?? ''
  const [number, setNumber] = useState(prefilledNumber)
  const [numberError, setNumberError] = useState('')
  const [code, setCode] = useState('')
  const [codeError, setCodeError] = useState('')
  const { login } = useAuth()

  const onUpdateNumber = (value: string) => {
    setNumber(value)
    setNumberError('')
  }

  const onUpdateCode = (value: string) => {
    setCode(value.toLowerCase())
    setCodeError('')
  }

  const onSubmit = async () => {
    if (number === 'admin') {
      if (await login(code)) {
        navigate(AppRoutes.Admin())
        return
      }
    }
    const [numberError, voucherYearIndex, voucherYear] = VoucherApplication.extractYearAndIndex(number)
    const [codeError, voucherCode] = VoucherApplication.extractCode(code)
    if (!numberError) {
      setNumber(VoucherApplication.formatYearAndIndex(voucherYearIndex, voucherYear))
    } else {
      setNumberError('Bitte gib die Nummer im Format 01-24 ein.')
    }
    if (!codeError) {
      setCode(voucherCode)
    } else {
      setCodeError('Bitte gib den Code im Format 1234-abcd-efgh ein.')
    }
    if (!numberError && !codeError) {
      navigate(AppRoutes.Voucher(number, code))
    }
  }

  return (
    <div className="c-login-page">
      <main className='c-login-page__container'>
        <div>Hier kannst du deine Gutschein-/Bonus-Nummer und den dazugehörigen Code eingeben:</div>
        <Textfield className='c-login-page__voucherNumber' id='voucherNumber' label='Gutschein-/Bonus Nummer' value={number} error={numberError} onChange={evt => onUpdateNumber(evt.target.value)} placeholder="z.B. 01-24"></Textfield>
        <Textfield className='c-login-page__voucherCode' id='voucherCode' label='Gutschein-/Bonus Code' value={code} error={codeError} onChange={evt => onUpdateCode(evt.target.value)} placeholder="z.B. 1234-abcd-efgh" onEnter={onSubmit}></Textfield>
        <Button classtype='primary' onClick={onSubmit}>Gutschein/Bonus laden</Button>
        <VoucherPreviousList></VoucherPreviousList>
      </main>
    </div>
  )
}
