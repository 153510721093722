import { useRoutes } from 'react-router-dom'
import './App.scss'
import { routes } from './pages/router'
import { VoucherMenu } from './shared/menu/VoucherMenu'
import { AuthLoadingState, useAuth } from './features/admin/AdminContext'
import { Spinner } from '@benjaminpetry/sisterhoodmassagen-design'

export const App = () => {
  const routesElement = useRoutes(routes)
  const { loadingState } = useAuth()

  return (
    <div className="c-app">
      {loadingState === AuthLoadingState.INITIALIZATION && <Spinner></Spinner>}
      {loadingState !== AuthLoadingState.INITIALIZATION && <div className='c-app__content'>
        <VoucherMenu></VoucherMenu>
        {routesElement}
      </div>}
    </div>
  )
}
