import { VoucherApplication } from '../application'
import { Voucher } from '../domain'
import './VoucherNotification.scss'

interface VoucherValidDateProps {
  voucher: Voucher
}

export const VoucherNotification = ({ voucher }: VoucherValidDateProps) => {
  const getNotificationText = (voucher: Voucher) => {
    if (voucher.isDeactivated) {
      return `Dieser ${VoucherApplication.getName(voucher)} ist deaktiviert.`
    } else if (voucher.isRedeemed) {
      return `Dieser ${VoucherApplication.getName(voucher)} wurde bereits eingelöst.`
    } else if (!voucher.isValid) {
      return `Dieser ${VoucherApplication.getName(voucher)} ist seit dem ${VoucherApplication.formatUTCDate(voucher.validUntil)} nicht mehr gültig.`
    }
    return `Dieser ${VoucherApplication.getName(voucher)} ist bis zum ${VoucherApplication.formatUTCDate(voucher.validUntil)} gültig.`
  }

  return <section className={`voucher-notification voucher-notification--${voucher.isValid ? 'valid' : 'invalid'} voucher-notification--${voucher.isRedeemed ? 'redeemed' : 'redeemable'} voucher-notification--${voucher.isDeactivated ? 'deactivated' : 'activated'}`}>
    {getNotificationText(voucher)}
  </section>
}
